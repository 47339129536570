<!-- login -->
<template>
  <div class="login-page" :style="{backgroundImage: `url(${logonBackgroundMap})`}">
    <ics-page-header :project-title="projectTitle" :layout="[]" />
    <div class="find-pass-word-inner">
      <div class="find-pass-word-form">
        <div class="find-pass-word-content">
          <div class="find-pass-word-content-title">
            <i class="el-icon-info" style="color: #1989fa !important;font-size: 24px;" />
            <span style="font-size: 14px;">请进行绑卡确认信息验证</span>
          </div>
          <div style="margin-top: 30px;">
            <el-form ref="appForm" :model="appForm" :rules="rules" label-width="140px" label-position="right">
              <div v-if="accountDetail.userType === '12'">
                <p style="margin-left: 30px; margin-top: -20px; font-size: 13px; color: red;">
                  *{{ notes }}
                </p>
                <el-row :gutter="80" type="flex" justify="center" align="middle" style="margin-top: 30px;">
                  <el-col :span="16">
                    <p style="color: #1D7FF2;font-size: 16px;line-height: 40px;margin: -20px 0px 20px 19px;">
                      验证银行账户：&nbsp;&nbsp;{{ utils.formatBankNo(accountDetail.bankInfo.bankAccountNumber) }}
                    </p>
                  </el-col>
                </el-row>
                <el-row :gutter="80" type="flex" justify="center" align="middle">
                  <el-col :span="16">
                    <el-form-item prop="amount" label="收款金额(分)：">
                      <el-input v-model="appForm.amount" style="width:80%;" placeholder="请输入收款金额" />
                      <div v-show="false">
                        <p><el-input /></p>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="80">
                  <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 60px;">
                    <el-button class="add-btn" @click="backBank">
                      返回
                    </el-button>
                    <el-button v-loading="loading.submit" class="add-btn" type="primary" @click="commit">
                      完成
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-if="['11', '13'].includes(accountDetail.userType)">
                <p style="margin-left: 60px; margin-top: -20px; font-size: 13px; color: red;">
                  *{{ notes }}
                </p>
                <el-row :gutter="80" type="flex" justify="center" align="middle" style="margin-top: 30px;">
                  <el-col :span="16">
                    <el-col :span="16">
                      <p style="width: 310px;color: #1D7FF2;font-size: 16px;line-height: 40px;margin: -20px 0px 20px -37px;">
                        开户人手机号码：&nbsp;&nbsp;{{ utils.isEffectiveCommon(accountDetail.bankInfo.bankPhoneNumber) }}
                      </p>
                    </el-col>
                  </el-col>
                </el-row>
                <el-row :gutter="80" type="flex" justify="center" align="middle">
                  <el-col :span="16">
                    <el-form-item prop="sMSCode" label="验证码：">
                      <el-input v-model="appForm.sMSCode" style="width:80%;" type="number" placeholder="请输入验证码" />
                      <div v-show="false">
                        <p><el-input /></p>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="80">
                  <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 60px;">
                    <el-button class="add-btn" @click="backBank">
                      返回
                    </el-button>
                    <el-button v-loading="loading.submit" class="add-btn" type="primary" @click="commit">
                      完成
                    </el-button>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cookie } from '@/assets/js/config'
import api from '@/assets/js/api'
import IcsPageHeader from '@/components/page-header'
export default {
  components: { IcsPageHeader },
  data () {
    return {
      userId: this.$route.query.userId,
      firmId: this.$route.query.firmId,
      projectTitle: '',
      logonBackgroundMap: '',
      loading: {
        detail: false,
        submit: false
      },
      appForm: {
        txSN: '',
        verifyWay: '',
        amount: '',
        sMSCode: ''
      },
      isBackEdit: 0,
      accountDetail: {},
      notes: '',
      rules: {}
    }
  },
  watch: {},
  created () {
    if (this.userId) {
      this.getDetail()
    }
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.logonBackgroundMap = result.logonPage.backgroundMap
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    getDetail () {
      this.loading.detail = true
      this.api.system.account.getBankDetail(this.userId).then(result => {
        const data = result.data.data
        if (data.bankInfo.provinceCode && data.bankInfo.cityCode && data.bankInfo.districtCode) {
          data.bankInfo.dsEnterpriseAddress = [data.bankInfo.provinceCode, data.bankInfo.cityCode, data.bankInfo.districtCode]
        }
        if (data.userType === '11' || data.userType === '13') {
          this.notes = '验证码可验证三次，三次失败后失效，需重新提交确认绑卡信息'
        } else if (data.userType === '12') {
          this.notes = '一次打款可最多进行 3 次打款验证，验证有效期为自申请之日起 3 个自然日内,验证码失效或验证失败后需重新进行绑卡信息提交'
        } else {
          this.notes = ''
        }
        this.accountDetail = data || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    commit () {
      if (['11', '13'].includes(this.accountDetail.userType)) {
        if (!this.appForm.sMSCode) {
          this.$message.error('请输入验证码')
          return false
        }
      }
      if (this.accountDetail.userType === '12') {
        if (!this.appForm.amount) {
          this.$message.error('请输入收款金额')
          return false
        }
      }
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          const data = this._.cloneDeep(this.appForm)
          let api = this.api.system.account.eJz4613
          this.loading.submit = true
          if (['11', '13'].includes(this.accountDetail.userType)) {
            data.verifyWay = '10'
            api(this.userId, data.verifyWay, '', data.sMSCode).then(result => {
              const eJz4613 = JSON.parse(result.data.data)
              console.log(eJz4613)
              if (eJz4613.code === '2000' && ['30', '15'].includes(eJz4613.status)) {
                this.$message.success(eJz4613.responseMessage ? eJz4613.responseMessage : eJz4613.message)
                this.$router.push({ name: 'account'})
              } else {
                this.$message.error(eJz4613.responseMessage ? eJz4613.responseMessage : eJz4613.message)
                this.loading.submit = false
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else if (this.accountDetail.userType === '12') {
            data.verifyWay = '20'
            api(this.userId, data.verifyWay, data.amount, '').then(result => {
              const eJz4613 = JSON.parse(result.data.data)
              console.log(eJz4613)
              if (eJz4613.code === '2000' && ['30', '15'].includes(eJz4613.status)) {
                this.$message.success(eJz4613.responseMessage ? eJz4613.responseMessage : eJz4613.message)
                this.$router.push({ name: 'account'})
              } else {
                this.$message.error(eJz4613.responseMessage ? eJz4613.responseMessage : eJz4613.message)
                this.loading.submit = false
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.$router.push({ name: 'account'})
          }
        }
      })
    },
    backBank () {
      this.$router.push({ name: 'account'})
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../../../assets/images/demo/login-big.png");
  background-size: 100% 100%;
}
.find-pass-word-inner {
  width: 840px;
  height: 550px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -370px;
  margin-top: -245px;
  padding: 40px 30px 40px;
  background-color: rgba(0, 0, 0, 0.6);
  .logo-inner {
    margin: 0 auto 10px;
    width: 380px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:25px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .find-pass-word-content {
    background: #fff;
    margin-top: 30px;
    width: 100%;
    height: 380px;

    .find-pass-word-content-title {
      border-bottom: 1px solid lavender;
    }
  }
}

</style>
<style scoped>
.el-step >>> .el-step__icon {
  width: 40px !important;
  height: 40px !important;
}
.el-step >>> .el-step__line{
  top: 18px !important;
}
.el-step >>>  .is-process .el-step__icon-inner {
  color: #000 !important;
}
.el-step >>> .is-process{
  color: #fff !important;
}
</style>
