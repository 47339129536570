var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-header",
    { staticClass: "header", attrs: { height: "48px" } },
    [
      _c("h1", { staticClass: "logo" }, [
        _c("a", { staticClass: "logo-title", attrs: { href: "/index" } })
      ]),
      _c(
        "div",
        { staticClass: "logo-subtitle", staticStyle: { "margin-top": "-5px" } },
        [
          _c("router-link", { attrs: { to: "/index" } }, [
            _vm._v(" " + _vm._s(_vm.projectTitle) + " ")
          ])
        ],
        1
      ),
      _vm._t("account", function() {
        return [
          _vm.layout && _vm.layout.length > 0
            ? _c(
                "div",
                { staticClass: "account" },
                [
                  _vm._t("before-welcome"),
                  _vm.layout.indexOf("welcome") !== -1
                    ? _c("span", { staticClass: "welcome-msg" }, [
                        _vm._v("您好！"),
                        _c("span", [_vm._v(_vm._s(_vm.empName))])
                      ])
                    : _vm._e(),
                  _vm._t("after-welcome"),
                  _vm.layout.indexOf("center") !== -1
                    ? _c(
                        "a",
                        {
                          staticClass: "account-link",
                          attrs: { href: "" + _vm.consoleDomain }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-gerenzhongxin"
                          }),
                          _c("span", {}, [_vm._v("个人中心")])
                        ]
                      )
                    : _vm._e(),
                  _vm.layout.indexOf("setting") !== -1
                    ? _c(
                        "a",
                        {
                          staticClass: "account-link",
                          attrs: { href: _vm.consoleDomain + "/user/setting" }
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-shezhi" }),
                          _c("span", {}, [_vm._v("设置")])
                        ]
                      )
                    : _vm._e(),
                  _vm.layout.indexOf("todo") !== -1
                    ? _c(
                        "el-badge",
                        {
                          attrs: {
                            value: _vm.untreatedTodoNum,
                            hidden: _vm.untreatedTodoNum > 0 ? false : true,
                            max: _vm.maxNum,
                            "is-dot":
                              _vm.isDot && _vm.untreatedTodoNum > 0
                                ? true
                                : false
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "account-link",
                              attrs: { to: { name: "todo" } },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickTodo")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-daibanshixiang"
                              }),
                              _c("span", {}, [_vm._v("待办事项")])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.layout.indexOf("message") !== -1
                    ? _c(
                        "el-badge",
                        {
                          attrs: {
                            value: _vm.unreadMessageNum,
                            hidden: _vm.unreadMessageNum > 0 ? false : true,
                            max: _vm.maxNum,
                            "is-dot":
                              _vm.isDot && _vm.unreadMessageNum > 0
                                ? true
                                : false
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "account-link",
                              attrs: { href: _vm.messageLink },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("clickMessage")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "iconfont icon-xiaoxi" }),
                              _c("span", {}, [_vm._v("消息")])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._t("before-login"),
                  _vm.layout.indexOf("logout") !== -1
                    ? _c(
                        "a",
                        {
                          staticClass: "account-link",
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.logOut }
                        },
                        [
                          _c("i", { staticClass: "iconfont icon-tuichu" }),
                          _c("span", {}, [_vm._v("退出")])
                        ]
                      )
                    : _vm._e(),
                  _vm._t("after-login")
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }